.projects-item {
  position: relative;
  overflow: hidden;
}




/* Service.css */

.service-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.service-tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.service-tab {
  padding: 10px 20px;
  background-color: #f8f9fa;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.service-tab:hover,
.service-tab.active {
  background-color: var(--primary);
  color: white;
}

.service-content {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.service-image-container {
  flex: 0 0 40%;
}

.service-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.service-text-content {
  flex: 1;
}

.service-content-title {
  font-size: 24px;
  margin-bottom: 15px;
  color: var(--primary);
}

.service-description,
.other-services {
  font-size: 16px;
  line-height: 1.6;
}

.service-description p,
.other-services p {
  margin-bottom: 15px;
}

.service-description ul,
.other-services ul {
  list-style-type: none;
  padding-left: 20px;
  margin-bottom: 15px;
}

.service-description li,
.other-services li {
  position: relative;
  padding-left: 15px;
  margin-bottom: 10px;
  text-align: justify;
}

.service-description li::before,
.other-services li::before {
  /* content: '•'; */
  position: absolute;
  left: 0;
  color: var(--primary);
}

.other-services {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e0e0e0;
}

@media (max-width: 768px) {
  .service-content {
    flex-direction: column;
  }

  .service-image-container {
    flex: 0 0 100%;
  }
}

/* Service.css */

.service-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.service-tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.service-tab {
  padding: 10px 20px;
  background-color: #f8f9fa;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.service-tab:hover,
.service-tab.active {
  background-color: #87c6fa;
  color: rgb(255, 252, 248);
}

.service-content {
  display: flex;
  gap: 20px;
}

.service-image-container {
  flex: 0 0 40%;
}

.service-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.service-text-content {
  flex: 1;
}

.service-content-title {
  font-size: 24px;
  margin-bottom: 15px;
  color: var(--primary);
}

.service-description {
  font-size: 16px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .service-content {
    flex-direction: column;
  }

  .service-image-container {
    flex: 0 0 100%;
  }
}
















.projects-content {
  /* position: relative; */
  top: -50px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  background: var(--light);
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.nav-item a {
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-item a:hover,
.nav-item a.active {
  background-color: var(--primary);
  color: var(--light);
}

/* Add any additional custom styles here */