/* .hardcastel-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.main-title {
  color: var(--dark-blue);
  text-align: center;
  margin-bottom: 20px;
}

.divider {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  margin: 20px 0;
}

.card {
  background-color: var(--light-blue);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
}

.card-title {
  color: var(--dark-blue);
  margin-bottom: 15px;
}

.card-title a {
  color: inherit;
  text-decoration: none;
}

.card-image {
  max-width: 50%;
  align-items: center;
  height: 220px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.card-text {
  margin-bottom: 10px;
}

.product-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.product-card {
  display: flex;
  flex-direction: column;
}

.product-list {
  list-style-type: none;
  padding: 0;
}

.product-list li {
  margin-bottom: 15px;
}

.product-list strong {
  color: var(--dark-blue);
  display: block;
  margin-bottom: 5px;
}

/* Mobile Styles */
/* @media (max-width: 768px) {
  .hardcastel-container {
    padding: 10px;
  }

  .card {
    padding: 15px;
  }

  .product-grid {
    grid-template-columns: 1fr;
  }

  .card-title {
    font-size: 1.2em;
  }

  .product-list li {
    margin-bottom: 10px;
  }
}


@media (min-width: 769px) {
  .product-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .product-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}  */



.hardcastel-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.main-title {
  color: var(--dark-blue);
  text-align: center;
  margin-bottom: 20px;
}

.divider {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  margin: 20px 0;
}

.res-service-image-container{
  width: 500px;
  height: 300px;
}


.card {
  background-color: var(--light-blue);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
}

.card-title {
  color: var(--dark-blue);
  margin-bottom: 15px;
}

.card-title a {
  color: inherit;
  text-decoration: none;
}

.card-image {
  max-width: 50%;
  align-items: center;
  height: 220px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.card-text {
  margin-bottom: 10px;
}

.product-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.product-card {
  display: flex;
  flex-direction: column;
}

.product-list {
  list-style-type: none;
  padding: 0;
}

.product-list li {
  margin-bottom: 15px;
}

.product-list strong {
  color: var(--dark-blue);
  display: block;
  margin-bottom: 5px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .hardcastel-container {
    padding: 10px;
  }

  .card {
    padding: 15px;
  }

  .product-grid {
    grid-template-columns: 1fr;
  }

  .card-title {
    font-size: 1.2em;
  }

  .product-list li {
    margin-bottom: 10px;
  }

  .responive_karthik_description{
    margin-left: -48px;
  }
}

/* Tablet and Desktop Styles */
@media (min-width: 769px) {
  .product-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .product-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}