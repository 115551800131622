/* .owl-theme .item {
    text-align: center;
    padding: 20px;
    border: 1px solid #ddd;
    background: #fff;
    margin: 5px;
    border-radius: 10px;
  }
  
  .img-container {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
  
  .img-container img {
    max-width: 100%;
    border-radius: 8px;
  }
  
  .testimonial-text {
    text-align: justify;
    color: #333;
  }
  
  .testimonial-name h5 {
    margin-top: 10px;
    font-weight: bold;
  }
  
  .owl-nav button.owl-prev,
  .owl-nav button.owl-next {
    background: none;
    border: none;
    font-size: 20px;
    color: #000000;
  }
  
  .owl-dots {
    text-align: center;
    margin-top: 20px;
  }
  
  .owl-dot span {
    background: #999;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
  }
  
  .owl-dot.active span {
    background: #000000;
  }
   */







   .owl-theme .item {
    text-align: center;
    padding: 20px;
    border: 1px solid #eea80f;
    background: #ffffff;
    margin: 5px;
    border-radius: 10px;
    max-width: 90%; /* Adjust this value to make the cards wider */
    width: 90%; /* Control the width of the cards */
    box-sizing: border-box;
    height: 350px; /* Set a fixed height for the card */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.img-container {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    height: 200px; /* Set a fixed height for the image container */
}

.img-container img {
    max-height: 100%; /* Ensure the image fits within the container */
    max-width: 100%;
    border-radius: 8px;
    object-fit: contain; /* Makes sure the image keeps its aspect ratio */
}

.testimonial-text {
    /* text-align: justify; */
    color: #333;
    flex-grow: 1; /* Ensures the text area takes up available space */
}

.testimonial-name h5 {
    margin-top: 10px;
    font-weight: bold;
}

.owl-nav button.owl-prev,
.owl-nav button.owl-next {
    background: none;
    border: none;
    font-size: 20px;
    color: #000000;
}

.owl-dots {
    text-align: center;
    margin-top: 20px;
}

.owl-dot span {
    background: #999;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
}

.owl-dot.active span {
    background: #000000;
}
