.zoom-image_logo {
    animation: zoomIn 3s infinite ease-in-out;
  }
  
  @keyframes zoomInOut {
    0% {
      transform: scale(1.0);  /* Normal size */
    }
    50% {
      transform: scale(1.0); /* Zoom in */
    }
    100% {
      transform: scale(1); /* Back to normal size */
  }
}



@keyframes ring {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-15deg);
  }
  30% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.phone-body {
  animation: ring 0.5s ease-in-out infinite;
}


.logo-container {
    
    top: 20px;
    left: 20px;
    z-index: 1000;
}

/* Optionally, add other styles here */



/* Style for the ABOUT US item */
.about-us {
  border-right: 1px solid #007bff; /* Adjust color to match your design */
  padding-right: 15px; /* Add padding to keep spacing consistent */
  margin-right: 10px; /* Optional: Add margin to separate the line */
}




/* From Uiverse.io by mi-series */ 
.btn12 {
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: #87c6fa;
  min-width: 200px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
  box-sizing: border-box;
  padding: 3px 12px;
  color: #000000;
  font-size: 14.5px;
  font-weight: bolder;
  /* letter-spacing: 1.2px; */
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;
}

.btn12:hover {
  opacity: .95;
}

.btn12 .animation12 {
  border-radius: 100%;
  animation: ripple 0.6s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
  }
}

.gif {
  transition: opacity 0.5s ease;
  opacity: 1;
}

/* .fade-out {
  opacity: 0;
} */


.chart-container12{
  width: 100%;
  max-width: 300px;
  margin: 0px auto;
  border: 1px solid #909206;
  border-style: solid;
  border-radius: 4px;
}

@media screen and (max-width: 567px) {
  .responive_home_icon{
    display: none;
  }
}


.marquee-container {
  overflow: hidden; /* Hide overflow for a clean look */
  white-space: nowrap; /* Prevent wrapping */
  margin-bottom: -30px;
}

@media (max-width: 992px) { /* Adjust this breakpoint as needed */
  .marquee-container {
      display: none; /* Hide marquee on mobile */
  }
}

.logo{
  width: auto;
  height: auto;
}