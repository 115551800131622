/* .btn-whatsapp-pulse {
	background: #25d366;
	color: white;
	position: fixed;
	bottom: 95px;
	
	font-size: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 0;
	height: 0;
	padding: 35px;
	text-decoration: none;
	border-radius: 50%;
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
	}
	80% {
		box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
	}
}

.btn-whatsapp-pulse-border {
	bottom: 120px;
	right: 20px;
	animation-play-state: paused;
}

.btn-whatsapp-pulse-border::before {
	content: "";
	position: absolute;
	border-radius: 50%;
	padding: 25px;
	border: 5px solid #25d366;
	opacity: 0.75;
	animation-name: pulse-border;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}

@keyframes pulse-border {
	0% {
		padding: 25px;
		opacity: 0.75;
	}
	75% {
		padding: 50px;
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

 */


 /* WhatsApp icon styles */
.btn-whatsapp-pulse {
	background: #25d366;
	color: white;
	position: fixed;
	bottom: 95px;
	right: 20px;
	font-size: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 0;
	height: 0;
	padding: 27px;
	text-decoration: none;
	border-radius: 50%;
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
  }
  
  @keyframes pulse {
	0% {
	  box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
	}
	80% {
	  box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
	}
  }
  
  /* Phone call icon styles for mobile view */
  .btn-phone-call {
	background: #007bff;
	color: white;
	position: fixed;
	bottom: 95px;
	right: 20px;
	font-size: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 0;
	height: 0;
	padding: 30px;
	text-decoration: none;
	border-radius: 50%;
  }
  
  /* Hide the phone call icon by default (for desktop) */
  .btn-phone-call {
	display: none;
  }
  
  /* Show the WhatsApp icon only on screens wider than 768px (desktop view) */
  @media (min-width: 768px) {
	.btn-phone-call {
	  display: none;
	}
	.btn-whatsapp-pulse {
	  display: flex;
	}
  }
  
  /* Show the phone call icon only on screens narrower than 768px (mobile view) */
  @media (max-width: 767px) {
	.btn-whatsapp-pulse {
	  display: none;
	}
	.btn-phone-call {
	  display: flex;
	}
  }
  