/* .testimonial-container {
    background-color: #1a1a1a;
}

.testimonial-item {
    color: #fff;
    border: 1px solid #000000;
    border-radius: 8px;
    padding: 15px;
}

.testimonial-quote {
    color: #ffffff;
}

.testimonial-inner {
    display: flex;
    align-items: center;
}

.testimonial-inner img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    margin-right: 15px;
}

.customer-text {
    background-color: #c9c4c43d;
    border-radius: 8px;
    margin-top: 15px;
    padding: 15px;
}

.testimonial-carousel .slick-prev, .testimonial-carousel .slick-next {
    color: #007bff;
}

.testimonial-carousel .slick-dots li button:before {
    color: #007bff;
}

.testimonial-carousel .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #fff;
}

@media (max-width: 768px) {
    .testimonial-container .row {
        flex-direction: column;
    }
    .testimonial-inner {
        flex-direction: column;
        text-align: center;
    }
    .testimonial-inner img {
        margin: 0 auto 10px;
    }
} */.testimonial-container {
  position: relative;
  max-width: 80rem;
  margin: 0 auto;
  /* padding: 2rem; */
  overflow: hidden;
  margin-top: -30px;
}


.testimonial-slider {
    display: flex;
    transition: transform 0.5s ease;
    gap: 3.3rem; /* This adds space between the cards */
  }

.testimonial-card {
  flex: 0 0 100%;
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  /* box-shadow: 
    0 10px 15px -3px rgba(0, 0, 0, 0.1), 
    0 4px 6px -2px rgba(0, 0, 0, 0.05),
    10px 10px 0 -3px #ffffff; */
  position: relative;
  z-index: 10;
}

.testimonial-image {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 4px solid white;
  margin-top: -3rem;
  margin-left: -3rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.testimonial-text {
  color: #4B5563;
  margin-top: 1rem;
  font-weight: bold;
  font-size: 25px;
  text-align: center;
}

.testimonial-name {
  font-size: 1.25rem;
  font-weight: 700;
  color: #67dd27;
  margin-top: 1rem;
}

.testimonial-background {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 30%;
  border-radius: 0 0 0.5rem 0.5rem;
  z-index: -10;
}

.slider-controls {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.slider-controls button {
  background-color: #878d97;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  cursor: pointer;
  border-radius: 0.25rem;
  z-index: 20;
}

.slider-controls button:hover {
  background-color: #87c6fa;
}

.slider-controls button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(251, 191, 36, 0.5);
}

.bg_hh{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1001%26quot%3b)' fill='none'%3e%3cpath d='M 0%2c492 C 57.6%2c397 172.8%2c39.6 288%2c17 C 403.2%2c-5.6 460.8%2c341.8 576%2c379 C 691.2%2c416.2 748.8%2c202.2 864%2c203 C 979.2%2c203.8 1036.8%2c387.2 1152%2c383 C 1267.2%2c378.8 1382.4%2c222.2 1440%2c182L1440 560L0 560z' fill='%23184a7e'%3e%3c/path%3e%3cpath d='M 0%2c429 C 96%2c354 288%2c70.8 480%2c54 C 672%2c37.2 768%2c324 960%2c345 C 1152%2c366 1344%2c196.2 1440%2c159L1440 560L0 560z' fill='%232264ab'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1001'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  background-repeat: round;
}