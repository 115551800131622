.inquiry-btn {
  position: fixed;
  top: 190px;
  right: -38px;
   /* Adjust this value for horizontal positioning */
  background-color: #87c6fa; /* Color similar to the attached image */
  color: white;
  border: none;
  padding: 10px 20px; /* Increased padding for a horizontal button */
  cursor: pointer;
  font-size: 16px;
  /* border-radius: 30px;  */
  z-index: 1000;
  display: flex;
  align-items: center; /* Align text and icon in center */
  justify-content: space-between; /* Space between icon and text */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add slight shadow for a 3D effect */
  transform: rotate(270deg);

      /* box-shadow: 0 0 10px rgba(89, 189, 222, 0.8), 0 0 20px rgba(89, 189, 222, 0.6);
      animation: glow 2s ease-in-out infinite alternate;
       */

       background-color: #87c6fa;
       animation: flash-bg 1.5s ease-in-out infinite;
  
}

@keyframes flash-bg {
  0%, 100% {
      background-color: #87c6fa;
  }
  50% {
      background-color: #87c6fa;
  }
}

.large-text {
font-size:12.5px; /* Adjust this value to make the text larger or smaller */
font-weight: bold;
margin-bottom: 15px;
}
.form-group {
margin-bottom: 15px;
}

.error {
color: red;
font-size: 2em;
margin-top: 5px;
display: block;
}

.thank-you-popup {
background: white;
padding: 20px;
border-radius: 5px;
box-shadow: 0 0 10px rgba(0,0,0,0.3);
text-align: center;
}

.thank-you-popup h2 {
margin-top: 0;
}

.thank-you-popup button {
margin-top: 15px;
padding: 5px 10px;
background-color: #87c6fa;
color: white;
border: none;
border-radius: 3px;
cursor: pointer;
}

.thank-you-popup button:hover {
background-color: #87c6fa;
}
/* General form container styling */
form {
  width: 100%;
  height: 300px;
  max-width: 600px; /* Max width to keep form centered and readable */
  margin: 0 auto; /* Center form on the page */
  padding: 10px;
  font-size: 10px; 
  background-color: #f9f9f9;
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Subtle shadow for depth */
  font-family: Montserrat, sans-serif;
  /* line-height: 0.5px; */
}

/* Form group spacing */
.form-group {
  margin-bottom: 20px; /* Space between form elements */
  display: flex;
  flex-direction: column; /* Stack label and input vertically */
}

/* Form container styling */
.form-container {
  position: relative;
  /* Existing styles */
}

/* Close button styling */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000; /* Adjust color as needed */
}

.close-btn:hover {
  color: #f00; /* Change color on hover if desired */
}


/* Label styling */
.form-group label {
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

/* Input fields styling */
.form-group input,
.form-group select,
.form-group textarea {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s;

}

/* Change input border color on focus */
.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #994203;
  outline: none;
}

/* Textarea styling */
.form-group textarea {
  resize: none; /* Prevent textarea resizing */
}

/* Submit button styling */
.submit-btn {
  background-color: #de9959;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 30px;
  transition: background-color 0.3s ease;
}

/* Submit button hover effect */
.submit-btn:hover {
  background-color: #ffac07;
}

/* Styling for the pre-filled message */
.pre-filled-message p {
  font-size: 14px;
  color: #777;
  font-style: italic;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 4px;
}

/* Optional - Responsive layout for smaller screens */
@media (max-width: 600px) {
  form {
      padding: 15px;
      height: 350px;
  }

  .submit-btn {
      padding: 10px;
      font-size: 14px;
  }
}


/* Optional: Style the button icon */
.inquiry-btn i {
  margin-right: 8px; /* Space between icon and text */
  font-size: 20px; /* Adjust icon size */
}

/* Inquiry form styling remains the same */
.form-container {
  position: fixed;
  top: 1px;
  right: 20px;
  /* width: 400px; */
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 70px 20px 20px 20px;
  z-index: 1000;
  height: 900px;
}

.form-group {
  margin-bottom: 15px;
}

.submit-btn {
  background-color: #87c6fa;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}


.zoom-image {
  animation: zoomInOut 3s infinite ease-in-out;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);  /* Normal size */
  }
  50% {
    transform: scale(1.2); /* Zoom in */
  }
  100% {
    transform: scale(1); /* Back to normal size */
}

}


@media screen and (max-width: 568px){
  .resposnive_footer{
     margin-right: 30px;
  }
  .resposnive_address_new{
    margin-left: 0px;
  }
}

@media screen and (min-width: 569px){
  .resposnive_address_new{
    margin-left: -70px;
  }
}